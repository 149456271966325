export const sortOptions = [
  {
    id: 'date',
    label: 'Date',
  },
  {
    id: 'name',
    label: 'Name',
  },
];
